const images={
    logo:require('./logo.svg').default,
    noImage:require('./no-image.png'),
    facebook:require('./facebook.svg').default,
    logobig:require('./LOGOPM2.png'),
    logobig2:require('./LOGOPM.png'),
    google:require('./google.svg').default,
    tn1:require('./tn1.jpg'),
    tn2:require('./tn2.jpg'),
    tn3:require('./tn3.jpg')
}
export default images